//Ref: https://firebase.google.com/docs/firestore/query-data/order-limit-data

import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  MoreHoriz as MoreHorizIcon,
  NavigateNext as NavigateNextIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  styled,
  Switch,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import {
  collection,
  DocumentData,
  documentId,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  Query,
  query,
  QueryEndAtConstraint,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  startAfter,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonPostedJobsDataTable from "@skeletons/SkeletonPostedJobsDataTable";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Status from "@components/Status";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  FIRESTORE_COLLECTIONS,
  JOB_POSTING_STATUS,
  LOCALE_SHORT,
  PAGINATION
} from "@utils/config";
import { auth, db, envOptions } from "@utils/firebase";
import { getPermission } from "@utils/getPermission";
import { resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface Column {
  id:
    | "jobTitle"
    | "enStatus"
    | "jaStatus"
    | "numOfApplications"
    | "updatedDate"
    | "isActive";
  label: string;
  align?: "left" | "right" | "center";
  isSortEnabled?: boolean;
}

interface Row {
  jobId: JobID;
  jobTitle?: MultiLingual<string>;
  enStatus: typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS];
  jaStatus: typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS];
  numOfApplications?: number;
  updatedDate?: Date;
  isActive: boolean;
  isActiveSwitchDisabled?: boolean;
}

interface Job extends JobProfile {
  id: string;
}

type FirestoreQueryParams = [
  Query<unknown>,
  ...Array<
    | QueryOrderByConstraint
    | QueryFieldFilterConstraint
    | QueryStartAtConstraint
    | QueryEndAtConstraint
    | QueryLimitConstraint
  >
];

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "jobTitle",
    label: "t_general_job_title",
    align: "left",
    isSortEnabled: false
  },
  {
    id: "enStatus",
    label: "t_employer_dashboard_job_posted_table_column_status_en"
  },
  {
    id: "jaStatus",
    label: "t_employer_dashboard_job_posted_table_column_status_ja"
  },
  {
    id: "numOfApplications",
    label: "t_employer_dashboard_job_posted_table_column_num_of_applications"
  },
  {
    id: "updatedDate",
    label: "t_employer_dashboard_job_posted_table_column_updated_date"
  },
  {
    id: "isActive",
    label: "t_employer_dashboard_job_posted_table_column_active"
  }
];

const StyledTablePagination = styled(TablePagination)({
  "& .MuiTablePagination-select, & .MuiInputBase-root": {
    width: "max-content"
  }
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    boxShadow: `0px 0px 6px ${colorPalette.black.hover["20"]}`
  }
});

const PostedJobsDataTable = () => {
  const [user] = useAuthState(auth);
  const companyData = useCompanyDetails();
  const companyProfile = useUserProfile();
  const toast = useToast();
  const navigate = useNavigate();

  const postedJobIds = companyData.value?.jobs ?? [];
  const canPostJob = getPermission(companyData, user, "canUpsertJob");

  const jobTitleOptions =
    resolveMultiLingual(companyData.value?.job_titles) ?? {};

  const [postedJobs, setPostedJobs] = useState<Array<Job>>([]);
  const [rowData, setRowData] = useState<Array<Row>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //Sorting & Pagination
  const [sortedBy, setSortedBy] = useState<Column["id"]>("updatedDate");
  const [orderDirection, setOrderDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    PAGINATION.TABLE_ROWS_PER_PAGE[0]
  );
  const [pageDirection, setPageDirection] = useState<"prev" | "next" | "none">(
    "none"
  );
  const [lastJob, setLastJob] = useState<DocumentData>();
  const [firstJob, setFirstJob] = useState<DocumentData>();
  const [currentJobId, setCurrentJobId] = useState<string>("");

  // Searching
  const methods = useForm({
    defaultValues: {
      searchTerm: { key: "", label: "" }
    }
  });
  const { control, watch } = methods;
  const searchedJob = watch("searchTerm");

  const getOrderBy = (sortedBy: string) => {
    const orderClauses = [];
    switch (sortedBy) {
      case "jobTitle":
        {
          const titleEnOrderBy = orderBy("job_title.en", orderDirection);
          const titleJaOrderBy = orderBy("job_title.ja", orderDirection);
          if (translate.getCurrentLocaleShort() === LOCALE_SHORT.EN) {
            orderClauses.push(titleEnOrderBy);
            orderClauses.push(titleJaOrderBy);
          } else {
            orderClauses.push(titleJaOrderBy);
            orderClauses.push(titleEnOrderBy);
          }
        }
        break;
      case "status":
      case "isActive":
        orderClauses.push(orderBy("status", orderDirection));
        break;
      case "numOfApplications":
        orderClauses.push(
          orderBy("metadata.num_of_applications", orderDirection)
        );
        break;
      case "updatedDate":
      default:
        orderClauses.push(orderBy("updated_at", orderDirection));
        break;
    }

    return orderClauses;
  };

  const getWhereBy = (searchedJobId = "") => {
    if (searchedJobId) {
      return where(documentId(), "==", searchedJobId);
    } else {
      const companyId = companyProfile.value?.company_id ?? "";
      // Here, companyId is global, so not passed through parameter.
      return where("company_id", "==", companyId);
    }
  };

  const handleSortRequest = (sortBy: Column["id"]) => {
    setSortedBy(sortBy);
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    // reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (page < newPage) {
      setPageDirection("next");
    } else {
      setPageDirection("prev");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    //reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  const fetchPostedJobs = async () => {
    try {
      if (postedJobIds.length > 0) {
        setLoading(true);
        const jobsData: Array<Job> = [];
        const jobsRef = collection(db, FIRESTORE_COLLECTIONS.JOBS);
        const searchedJobId =
          searchedJob && searchedJob.key ? searchedJob.key : "";

        const queryParams: FirestoreQueryParams = [jobsRef];
        queryParams.push(getWhereBy(searchedJobId));
        queryParams.push(...getOrderBy(sortedBy));

        switch (pageDirection) {
          case "next":
            {
              if (lastJob) {
                queryParams.push(startAfter(lastJob));
              }
              queryParams.push(limit(rowsPerPage));
            }
            break;
          case "prev":
            {
              if (firstJob) {
                queryParams.push(endBefore(firstJob));
              }
              queryParams.push(limitToLast(rowsPerPage));
            }
            break;
          case "none":
          default:
            queryParams.push(limit(rowsPerPage));
            break;
        }

        const jobsQuery = query(...queryParams);
        const querySnapshot = await getDocs(jobsQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            jobsData.push({
              id: doc.id,
              ...(doc.data() as JobProfile)
            });
          });

          setPostedJobs(jobsData);
          // update the first and last job of the current page
          setFirstJob(querySnapshot.docs[0]);
          setLastJob(querySnapshot.docs[querySnapshot.docs.length - 1]);

          const tableData = jobsData?.map((singleJob) => {
            return {
              jobId: singleJob?.id,
              jobTitle: singleJob.job_title,
              enStatus: singleJob?.status
                .en as typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS],
              jaStatus: singleJob?.status
                .ja as typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS],
              numOfApplications: singleJob.metadata?.num_of_applications ?? 0,
              updatedDate: singleJob?.updated_at?.toDate(),
              isActive:
                singleJob.status.en === JOB_POSTING_STATUS.FLAGGED ||
                singleJob.status.en === JOB_POSTING_STATUS.DRAFT ||
                singleJob.status.en === JOB_POSTING_STATUS.HIDDEN ||
                singleJob.status.ja === JOB_POSTING_STATUS.FLAGGED ||
                singleJob.status.ja === JOB_POSTING_STATUS.DRAFT ||
                singleJob.status.ja === JOB_POSTING_STATUS.HIDDEN
                  ? false
                  : true,
              isActiveSwitchDisabled:
                singleJob.status.en === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.status.en === JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.status.en === JOB_POSTING_STATUS.HIDDEN ||
                singleJob.status.ja === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.status.ja === JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.status.ja === JOB_POSTING_STATUS.HIDDEN
                  ? false
                  : true
            };
          });
          setRowData(tableData);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    fetchPostedJobs();
  }, [orderDirection, sortedBy, rowsPerPage, page, postedJobIds]);

  useEffect(() => {
    // Reset pagination, firstJob, and lastJob when searchedJob changes
    setPage(0);
    setPageDirection("none");
    setFirstJob(undefined);
    setLastJob(undefined);

    // Fetch applications again with the updated searchedJob
    fetchPostedJobs();
  }, [searchedJob]);

  // To handle active/inactive switch
  const handleStatusSwitchSucess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_update"), "success");
  };

  const handleStatusSwitchFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleSwitchActiveStatus = (jobId: string, isActive: boolean) => {
    try {
      if (jobId && companyData?.setJobValue) {
        //FIXME: Will optimize this approach later after Refactor is done to change job type to Record.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const jobData: any = postedJobs?.find(
          (singleJob) => singleJob.id === jobId
        );
        if (jobData) {
          jobData.status = isActive
            ? JOB_POSTING_STATUS.HIDDEN
            : JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
          jobData.status.en = isActive
            ? JOB_POSTING_STATUS.HIDDEN
            : JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
          jobData.status.ja = isActive
            ? JOB_POSTING_STATUS.HIDDEN
            : JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;

          delete jobData.id; //Remove id from Job data

          companyData.setJobValue(
            jobId,
            jobData as JobProfile,
            handleStatusSwitchSucess,
            handleStatusSwitchFail
          );

          // Temporary code to immediately reflect changes
          const index = rowData.findIndex(
            (singleJob) => singleJob.jobId === jobId
          );
          if (index !== -1) {
            const updatedRowsData = [...rowData];
            updatedRowsData[index].enStatus = isActive
              ? JOB_POSTING_STATUS.HIDDEN
              : JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
            updatedRowsData[index].jaStatus = isActive
              ? JOB_POSTING_STATUS.HIDDEN
              : JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
            updatedRowsData[index].isActive = !isActive;
            updatedRowsData[index].isActiveSwitchDisabled = isActive
              ? false
              : true;
            setRowData(updatedRowsData);
          }
        } else {
          handleStatusSwitchFail();
        }
      }
    } catch (e) {
      handleStatusSwitchFail();
    }
  };

  //Actions - Copy, Share, Edit
  const [openActionMenu, setOpenActionMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = (
    event: MouseEvent<HTMLButtonElement>,
    jobId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActionMenu(true);
    setCurrentJobId(jobId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenActionMenu(false);
  };

  // To handle copy to clipboard for sharing link
  const handleCopyToClipboard = (jobId: string) => {
    navigator.clipboard.writeText(
      `${
        envOptions.frontendUrl
          ? envOptions.frontendUrl
          : "https://" + window.location.hostname
      }/${translate.getCurrentLocale()}/jobs/${jobId}?hide_search=1`
    );
    toast.kampai(intl.get("t_general_link_copied"), "success");
  };

  return loading ? (
    <>
      <Stack direction="row-reverse" mb={2.5}>
        <Box
          noValidate
          component="form"
          width={{ xs: "100%", sm: 280, md: 300 }}>
          <TypeableSelect
            disablePortal={false}
            name="searchTerm"
            startAdornment={<SearchIcon />}
            control={control}
            placeholder={intl.get(
              "t_employer_dashboard_job_applications_table_search_placeholder"
            )}
            options={Object.values(jobTitleOptions)}
          />
        </Box>
      </Stack>
      <SkeletonPostedJobsDataTable />
    </>
  ) : postedJobIds.length === 0 ? (
    <Stack spacing={3} height={400} justifyContent="center" alignItems="center">
      <Typography variant="h3">
        {intl.get("t_employer_dashboard_job_posted_table_no_posted_jobs_yet")}
      </Typography>

      {user?.emailVerified && canPostJob ? (
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(
              `/${translate.getCurrentLocale()}/employers/jobs/new/${translate.getCurrentLocaleShort()}/company-information`
            )
          }>
          {intl.get("t_employer_dashboard_post_job_button")}
        </Button>
      ) : (
        <Tooltip
          arrow
          placement="bottom"
          enterTouchDelay={0}
          title={
            !user?.emailVerified
              ? intl.get("t_general_verify_email")
              : !canPostJob
              ? intl.get("t_general_no_permission")
              : ""
          }>
          <span>
            <Button disabled size="medium" endAdornment={<NavigateNextIcon />}>
              {intl.get("t_employer_dashboard_post_job_button")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Stack>
  ) : (
    <>
      <Stack direction="row-reverse" mb={2.5}>
        <Box
          noValidate
          component="form"
          width={{ xs: "100%", sm: 280, md: 300 }}>
          <TypeableSelect
            data-testid="employer_dashboard_search_job_title_select"
            disablePortal={false}
            name="searchTerm"
            startAdornment={<SearchIcon />}
            control={control}
            placeholder={intl.get(
              "t_employer_dashboard_job_applications_table_search_placeholder"
            )}
            options={Object.values(jobTitleOptions)}
          />
        </Box>
      </Stack>
      <DataTableWrapper>
        <TableHead>
          <TableRow>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                width="20%"
                align={singleColumn?.align ?? "center"}>
                {singleColumn?.isSortEnabled === false ? (
                  <Typography variant="subtitle5">
                    {intl.get(singleColumn.label)}
                  </Typography>
                ) : (
                  <TableSortLabel
                    sx={{
                      pl:
                        !singleColumn?.align || singleColumn?.align == "center"
                          ? 3
                          : 0
                    }}
                    onClick={() => handleSortRequest(singleColumn.id)}
                    active={sortedBy === singleColumn.id}
                    direction={orderDirection}>
                    <Typography variant="subtitle5">
                      {intl.get(singleColumn.label)}
                    </Typography>
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
            {/* Note: Last TableCell has no title. Used to display actions like share, edit on hover */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((singleData) => (
            <TableRow key={singleData?.jobId} className="hoverable-row">
              <TableCell align="left">
                {singleData.enStatus == JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleData.enStatus == JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleData.jaStatus == JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleData.jaStatus == JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ? (
                  <Link
                    href={`/${translate.getCurrentLocale()}/jobs/${
                      singleData?.jobId
                    }?hide_search=1`}
                    target="_blank"
                    color="inherit">
                    <Typography variant="body2">
                      {resolveMultiLingual(singleData?.jobTitle)}
                    </Typography>
                  </Link>
                ) : (
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        {intl.get(
                          "t_employer_dashboard_application_job_not_in_search"
                        )}
                      </Typography>
                    }>
                    <span>
                      <Typography variant="body2">
                        {resolveMultiLingual(singleData?.jobTitle)}
                      </Typography>
                    </span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="center">
                <Status label={singleData?.enStatus} />
              </TableCell>
              <TableCell align="center">
                <Status label={singleData?.jaStatus} />
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">
                  {singleData?.numOfApplications}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {dayjs(singleData?.updatedDate)
                  .locale(translate.getCurrentLocaleShort())
                  .fromNow()}
              </TableCell>
              <TableCell align="center">
                <Switch
                  checked={singleData?.isActive}
                  disabled={singleData?.isActiveSwitchDisabled}
                  onChange={() =>
                    handleSwitchActiveStatus(
                      singleData?.jobId,
                      singleData?.isActive
                    )
                  }
                />
              </TableCell>
              <TableCell align="center">
                <Box className="additional-column">
                  <Stack
                    flexDirection="row"
                    display={{ xs: "none", md: "flex" }}>
                    <Link
                      href={`/${translate.getCurrentLocale()}/employers/jobs/new/company-information?copy_from_job_id=${
                        singleData?.jobId
                      }`}
                      target="_blank"
                      color="inherit">
                      <ActionButton icon="content_copy" color="black" />
                    </Link>
                    <ActionButton
                      icon="share"
                      color="black"
                      handleAction={() =>
                        handleCopyToClipboard(singleData?.jobId)
                      }
                    />
                    <Link
                      href={`/${translate.getCurrentLocale()}/employers/jobs/settings/${
                        singleData?.jobId
                      }`}
                      target="_blank"
                      color="inherit">
                      <ActionButton icon="edit" color="black" />
                    </Link>
                  </Stack>
                </Box>
                <Box display={{ xs: "block", md: "none" }}>
                  <IconButton
                    onClick={(event) =>
                      handleMenuOpen(event, singleData?.jobId)
                    }>
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
          <StyledMenu
            anchorEl={anchorEl}
            id="action-menu"
            open={openActionMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            elevation={0}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom"
            }}>
            <MenuItem color="text.primary" onClick={handleMenuClose}>
              <Link
                href={`/${translate.getCurrentLocale()}/employers/jobs/new/company-information?copy_from_job_id=${currentJobId}`}
                target="_blank"
                color="inherit"
                underline="none">
                <ActionButton icon="content_copy" color="black" />
                Copy
              </Link>
            </MenuItem>
            <MenuItem color="text.primary" onClick={handleMenuClose}>
              <ActionButton
                icon="share"
                color="black"
                handleAction={() => handleCopyToClipboard(currentJobId)}
              />
              Share
            </MenuItem>
            <MenuItem color="text.primary" onClick={handleMenuClose}>
              <Link
                underline="none"
                href={`/${translate.getCurrentLocale()}/employers/jobs/settings/${currentJobId}`}
                target="_blank"
                color="inherit">
                <ActionButton icon="edit" color="black" />
                Edit
              </Link>
            </MenuItem>
          </StyledMenu>
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTablePagination
              rowsPerPageOptions={PAGINATION.TABLE_ROWS_PER_PAGE}
              count={postedJobIds?.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={intl.get("t_general_table_pagination_label")}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </DataTableWrapper>
    </>
  );
};

export default PostedJobsDataTable;
