import React, { useEffect, useState } from "react";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Divider, Stack, Tab } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonJobDetailCard from "@skeletons/SkeletonJobDetailCard";

import Button from "@components/Button";
import JobDetailCard from "@components/JobDetailCard";
import JobStatusAlert from "@components/JobStatusAlert";
import Paper from "@components/Paper";
import { TabPanel, Tabs } from "@components/Tab";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";

import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobProfile from "@interfaces/database/JobProfile";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";
import JobProfileWrapped from "@interfaces/functions/JobProfileWrapped";

import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLY_STATUS_CODE,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_POSTING_STATUS,
  JOB_STATUS_TAGS,
  LOCALE,
  LOCALE_SHORT
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { auth, db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { getPermission } from "@utils/getPermission";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

const ENGLISH_TAB_VALUE = 0;
const JAPANESE_TAB_VALUE = 1;

const JobPreview = () => {
  const {
    jobData
  }: {
    jobData: JobProfile;
  } = useOutletContext();
  const { job_id: jobId } = useParams();
  const navigate = useNavigate();
  const currentLocale = translate.getCurrentLocale();
  const [user] = useAuthState(auth);
  const companyDetails = useCompanyDetails();
  const [jobDetails, setJobDetails] = useState<JobProfileWrapped>();
  const [isJobDataLoading, setIsJobDataLoading] = useState<boolean>(true);
  const [jobAdditionalFileMetaData, setJobAdditionalFileMetaData] = useState<
    UploadedFileInformation | undefined
  >(jobData?.additional_file);
  const [activeTabValue, setActiveTabValue] = useState<number>(
    currentLocale === LOCALE.EN ? ENGLISH_TAB_VALUE : JAPANESE_TAB_VALUE
  );
  const canViewEnJob =
    getPermission(companyDetails, user, "canViewJob") ||
    jobData?.status?.en == JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
    jobData?.status?.en == JOB_POSTING_STATUS.OK_MANUAL_REVIEWED;
  const canViewJaJob =
    getPermission(companyDetails, user, "canViewJob") ||
    jobData?.status?.ja == JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
    jobData?.status?.ja == JOB_POSTING_STATUS.OK_MANUAL_REVIEWED;

  useEffect(() => {
    if (!jobId) {
      return;
    }
    setIsJobDataLoading(true);
    (async () => {
      if (jobData && jobData.company_id) {
        const companyRef = doc(
          db,
          FIRESTORE_COLLECTIONS.COMPANIES,
          jobData.company_id
        );
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          let companyData = companySnap.data() as CompanyProfile;

          let agencyData;
          if (jobData.client_company_id) {
            const clientCompanyRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              jobData.client_company_id
            );
            const clientCompanySnap = await getDoc(clientCompanyRef);
            if (clientCompanySnap.exists()) {
              agencyData = companyData;
              companyData = clientCompanySnap.data() as CompanyProfile;
            }
          }

          setJobDetails({
            job_id: jobId,
            job_profile: jobData,
            company_profile: companyData,
            agency_profile: agencyData
          });
        }
      }
      setIsJobDataLoading(false);
    })();
  }, [jobId, jobData]);

  const statusTags: Array<
    typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
  > = [JOB_STATUS_TAGS.ACTIVE];
  if (jobDetails?.job_profile.updated_at) {
    if (
      getDaysAgo(jobDetails?.job_profile.updated_at) <=
      JOB_NEW_TAG_LAST_UPDATED_DAY
    ) {
      statusTags.push(JOB_STATUS_TAGS.NEW);
    }
  }

  useEffect(() => {
    setJobAdditionalFileMetaData(jobData?.additional_file);
  }, [jobData?.additional_file]);

  const handleTabChange = (newValue: number) => {
    setActiveTabValue(newValue);
  };

  const NoJobPreviewPermission = () => {
    return (
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get("t_general_no_job_access")}
        </Typography>
        <Button
          size="medium"
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/employers/dashboard`)
          }>
          {intl.get("t_general_go_to_dashboard")}
        </Button>
      </Stack>
    );
  };

  return (
    <Paper>
      <Tabs value={activeTabValue} aria-label="applications-table">
        <Tab
          sx={{ p: 2 }}
          value={ENGLISH_TAB_VALUE}
          key={ENGLISH_TAB_VALUE}
          onClick={() => handleTabChange(ENGLISH_TAB_VALUE)}
          label={intl.get("t_general_english")}
          className={activeTabValue === ENGLISH_TAB_VALUE ? "Mui-selected" : ""}
        />
        <Tab
          sx={{ p: 2 }}
          value={JAPANESE_TAB_VALUE}
          key={JAPANESE_TAB_VALUE}
          onClick={() => handleTabChange(JAPANESE_TAB_VALUE)}
          label={intl.get("t_general_japanese")}
          className={
            activeTabValue === JAPANESE_TAB_VALUE ? "Mui-selected" : ""
          }
        />
      </Tabs>
      <Divider />
      <TabPanel
        value={activeTabValue}
        index={ENGLISH_TAB_VALUE}
        key={ENGLISH_TAB_VALUE}>
        <>
          {canViewEnJob ? (
            <>
              <Stack direction="row-reverse" my={2.5}>
                <Button
                  variant="outlined"
                  startAdornment={<EditIcon />}
                  handleClick={() =>
                    navigate(
                      `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${
                        LOCALE_SHORT.EN
                      }/company-information`
                    )
                  }>
                  {intl.get(
                    "t_job_post_job_confirmation_step_edit_english_post_btn"
                  )}
                </Button>
              </Stack>
              <Box mb={2.5}>
                <JobStatusAlert
                  status={
                    jobData?.status
                      ?.en as typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS]
                  }
                  jobId={jobId}
                  intl={intlEn}
                />
              </Box>
              {isJobDataLoading || !jobDetails || !jobData ? (
                <SkeletonJobDetailCard />
              ) : (
                <JobDetailCard
                  intl={intlEn}
                  mode="employer_preview"
                  visaSponsorship={
                    jobDetails?.job_profile?.job_overview?.visa_sponsorship
                  }
                  jobContractType={
                    jobDetails?.job_profile?.job_overview?.contract_type
                  }
                  remoteAllowed={
                    jobDetails?.job_profile?.job_overview?.remote_possible
                  }
                  jaProficiency={
                    jobDetails?.job_profile?.language_requirement?.at(0)
                      ?.proficiency
                  }
                  enProficiency={
                    jobDetails?.job_profile?.language_requirement?.at(1)
                      ?.proficiency
                  }
                  jobDescription={resolveMultiLingual(
                    jobDetails?.job_profile?.job_description,
                    LOCALE.EN
                  )}
                  experienceRequirement={
                    jobDetails?.job_profile.job_overview?.minimum_experience
                  }
                  mustRequirements={resolveMultiLingual(
                    jobDetails?.job_profile?.must_requirements,
                    LOCALE.EN
                  )}
                  languageRequirement={
                    jobDetails.job_profile.language_requirement
                  }
                  jobApplyStatusCode={JOB_APPLY_STATUS_CODE.HIDDEN}
                  jobStatuses={statusTags}
                  jobId={jobId ?? ""}
                  updatedDate={
                    new Date(jobDetails.job_profile.updated_at.seconds * 1000)
                  }
                  companyLogo={getFileURL(
                    FILES_LOCATION_COMPANY_LOGO,
                    jobDetails?.job_profile?.client_company_id
                      ? jobDetails.job_profile.client_company_id
                      : jobDetails?.job_profile?.company_id,
                    jobDetails?.company_profile?.logo?.extension,
                    jobDetails?.company_profile?.logo?.uploaded_at
                  )}
                  companyName={resolveMultiLingual(
                    jobDetails?.company_profile?.name,
                    LOCALE.EN
                  )}
                  companyWebsite={jobDetails?.company_profile?.website_url}
                  agencyName={resolveMultiLingual(
                    jobDetails?.agency_profile?.name,
                    LOCALE.EN
                  )}
                  jobTitle={resolveMultiLingual(
                    jobDetails?.job_profile?.job_title,
                    LOCALE.EN
                  )}
                  location={jobDetails?.job_profile?.job_overview?.location}
                  salary={jobDetails?.job_profile?.job_overview?.salary}
                  requiredSkills={jobDetails?.job_profile?.skills}
                  otherDetails={resolveMultiLingual(
                    jobDetails?.job_profile?.additional_information,
                    LOCALE.EN
                  )}
                  additionInfoFileDetails={jobAdditionalFileMetaData}
                  cvFileRequirements={
                    jobDetails?.job_profile?.cv_file_requirements
                  }
                  hiringFlow={jobDetails?.job_profile?.hiring_flow}
                />
              )}
            </>
          ) : (
            <NoJobPreviewPermission />
          )}
        </>
      </TabPanel>
      <TabPanel
        value={activeTabValue}
        index={JAPANESE_TAB_VALUE}
        key={JAPANESE_TAB_VALUE}>
        {canViewJaJob ? (
          <>
            <Stack direction="row-reverse" my={2.5}>
              <Button
                variant="outlined"
                startAdornment={<EditIcon />}
                handleClick={() =>
                  navigate(
                    `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${
                      LOCALE_SHORT.JA
                    }/company-information`
                  )
                }>
                {intl.get(
                  "t_job_post_job_confirmation_step_edit_japanese_post_btn"
                )}
              </Button>
            </Stack>
            <Box mb={2.5}>
              <JobStatusAlert
                status={
                  jobData?.status
                    ?.ja as typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS]
                }
                jobId={jobId}
                intl={intlJa}
              />
            </Box>
            {isJobDataLoading || !jobDetails || !jobData ? (
              <SkeletonJobDetailCard />
            ) : (
              <JobDetailCard
                intl={intlJa}
                mode="employer_preview"
                visaSponsorship={
                  jobDetails?.job_profile?.job_overview?.visa_sponsorship
                }
                jobContractType={
                  jobDetails?.job_profile?.job_overview?.contract_type
                }
                remoteAllowed={
                  jobDetails?.job_profile?.job_overview?.remote_possible
                }
                jaProficiency={
                  jobDetails?.job_profile?.language_requirement?.at(0)
                    ?.proficiency
                }
                enProficiency={
                  jobDetails?.job_profile?.language_requirement?.at(1)
                    ?.proficiency
                }
                jobDescription={resolveMultiLingual(
                  jobDetails?.job_profile?.job_description,
                  LOCALE.JA
                )}
                experienceRequirement={
                  jobDetails?.job_profile.job_overview?.minimum_experience
                }
                mustRequirements={resolveMultiLingual(
                  jobDetails?.job_profile?.must_requirements,
                  LOCALE.JA
                )}
                languageRequirement={
                  jobDetails.job_profile.language_requirement
                }
                jobApplyStatusCode={JOB_APPLY_STATUS_CODE.HIDDEN}
                jobStatuses={statusTags}
                jobId={jobId ?? ""}
                updatedDate={
                  new Date(jobDetails.job_profile.updated_at.seconds * 1000)
                }
                companyLogo={getFileURL(
                  FILES_LOCATION_COMPANY_LOGO,
                  jobDetails?.job_profile?.client_company_id
                    ? jobDetails.job_profile.client_company_id
                    : jobDetails?.job_profile?.company_id,
                  jobDetails?.company_profile?.logo?.extension,
                  jobDetails?.company_profile?.logo?.uploaded_at
                )}
                companyName={resolveMultiLingual(
                  jobDetails?.company_profile?.name,
                  LOCALE.JA
                )}
                companyWebsite={jobDetails?.company_profile?.website_url}
                agencyName={resolveMultiLingual(
                  jobDetails?.agency_profile?.name,
                  LOCALE.JA
                )}
                jobTitle={resolveMultiLingual(
                  jobDetails?.job_profile?.job_title,
                  LOCALE.JA
                )}
                location={jobDetails?.job_profile?.job_overview?.location}
                salary={jobDetails?.job_profile?.job_overview?.salary}
                requiredSkills={jobDetails?.job_profile?.skills}
                otherDetails={resolveMultiLingual(
                  jobDetails?.job_profile?.additional_information,
                  LOCALE.JA
                )}
                additionInfoFileDetails={jobAdditionalFileMetaData}
                cvFileRequirements={
                  jobDetails?.job_profile?.cv_file_requirements
                }
                hiringFlow={jobDetails?.job_profile?.hiring_flow}
              />
            )}
          </>
        ) : (
          <NoJobPreviewPermission />
        )}
      </TabPanel>
    </Paper>
  );
};

export default JobPreview;
