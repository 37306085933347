import { ReactNode, useEffect, useState } from "react";

import { Box, CircularProgress, Button as MuiButton } from "@mui/material";
import { keyframes, styled } from "@mui/system";

import { colorPalette } from "@utils/theme";

interface ButtonProps {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "error";
  variant?: "contained" | "outlined" | "text" | "light";
  children?: ReactNode;
  component?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: "submit" | "reset" | "button";
  fullWidth?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  loading?: boolean;
  isLoadable?: boolean;
  formId?: string;
}

// Keyframes for continuous shine effect
const shine = keyframes`
  0% { left: -100%; }
  50% { left: 100%; }
  100% { left: 100%; }
`;

// Styled Button
const StyledButton = styled(MuiButton)<ButtonProps>(({ color, variant }) => ({
  "position": "relative",
  "overflow": "hidden",
  "background":
    variant === "contained"
      ? color === "primary"
        ? colorPalette.blue.base
        : color === "secondary"
        ? colorPalette.orange.base
        : colorPalette.red.base
      : "transparent",
  "color":
    variant === "contained"
      ? colorPalette.white.base
      : color === "primary"
      ? colorPalette.blue.base
      : colorPalette.orange.base,

  /* eslint-disable quotes */
  "&::after": {
    content: '""',
    /* eslint-enable quotes */
    position: "absolute",
    top: 0,
    left: "-100%",
    width: "200%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.2)",
    transform: "rotate(20deg)",
    animation: `${shine} 2s infinite linear`
  },

  "&:hover": {
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)"
  },

  "&:active": {
    transform: "scale(0.98)"
  }
}));

const ShinyButton = ({
  size = "medium",
  color = "primary",
  variant = "contained",
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  loading = false,
  isLoadable = false,
  children = "",
  handleClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  formId,
  ...rest
}: ButtonProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <StyledButton
      size={size}
      variant={variant}
      color={color}
      disabled={disabled}
      startIcon={startAdornment}
      endIcon={endAdornment}
      onClick={() => {
        if (isLoadable) {
          setLoading(true);
        }
        if (!loading && !isLoading) {
          handleClick();
        }
      }}
      form={formId}
      {...rest}>
      <Box visibility={isLoading ? "hidden" : "visible"}>{children}</Box>
      {isLoading && (
        <CircularProgress
          size={size === "large" ? 23 : size === "medium" ? 21 : 20}
          color="inherit"
          sx={{
            position: "absolute"
          }}
        />
      )}
    </StyledButton>
  );
};

export default ShinyButton;
