import { useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import CompanyManagerIAM from "@interfaces/database/CompanyManagerIAM";

import {
  FIRESTORE_COLLECTIONS,
  USER_PERMISSIONS,
  USER_PERMISSIONS_T_LABELS,
  USER_TYPE
} from "@utils/config";
import { auth, db, functions } from "@utils/firebase";
import { getPermission } from "@utils/getPermission";
import translate, { intl } from "@utils/translate";

interface UserPermissionsFormData {
  permissions: Array<keyof CompanyManagerIAM>;
}

const UserPermissions = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { user_id: userId = "" } = useParams();
  const userProfile = useUserProfile();
  const companyData = useCompanyDetails();
  const toast = useToast();
  const canEditIAM = getPermission(companyData, user, "canEditIAM");

  const [updateUserPermissions, isUpdateUserPermissionsLoading] =
    useHttpsCallable(functions, "updateUserPermissions");

  const USER_PERMISSIONS_OPTIONS = useMemo(() => {
    return useOptions(USER_PERMISSIONS, USER_PERMISSIONS_T_LABELS).map(
      (option) => ({
        label: option.label,
        value: option.key
      })
    );
  }, [userId, companyData]);

  // validation schema
  const schema = yup.object({
    permissions: yup.array()
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      permissions:
        userId && companyData.value?.managers?.[userId]?.permissions
          ? (Object.entries(companyData.value?.managers?.[userId]?.permissions)
              .filter(([_, value]) => value === true)
              .map(([key]) => key) as Array<keyof CompanyManagerIAM>)
          : []
    }
  });

  const { handleSubmit, control, setValue, reset } = methods;

  useEffect(() => {
    if (userId && companyData.value?.managers?.[userId]?.permissions) {
      reset({
        permissions: Object.entries(
          companyData.value?.managers?.[userId]?.permissions ?? {}
        )
          .filter(([_, value]) => value === true)
          .map(([key]) => key) as Array<keyof CompanyManagerIAM>
      });
    }
  }, [companyData.value?.managers?.[userId]]);

  useEffect(() => {
    if (userId) {
      const checkUserIsValidOrNot = async () => {
        const userDocRef = doc(db, FIRESTORE_COLLECTIONS.USERS, userId);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          toast.kampai(intl.get("t_auth_error_user_not_found"), "error");
          navigate(
            `/${translate.getCurrentLocale()}/employers/settings/manage-team`
          );
        }
      };
      checkUserIsValidOrNot();
    }
    if (
      !companyData?.value?.managers?.[userId]?.permissions &&
      userProfile.value?.user_type !== USER_TYPE.COMPANY
    ) {
      toast.kampai("t_toast_error_not_company_manager", "error");
      navigate(
        `/${translate.getCurrentLocale()}/employers/settings/manage-team`
      );
    }
  }, [userId]);

  const handlePermissionChange = async (formData: UserPermissionsFormData) => {
    if (userId) {
      const permissions = {
        ...companyData.value?.managers?.[userId]?.permissions
      };

      // Reset all permissions to false
      Object.entries(permissions).forEach(([key]) => {
        permissions[key as keyof typeof permissions] = false;
      });

      // Set permissions from formData.permissions to true
      formData.permissions.forEach((key) => {
        if (key in permissions) {
          permissions[key as keyof typeof permissions] = true;
        }
      });

      try {
        await updateUserPermissions({
          userId,
          permissions
        });
        toast.kampai(
          intl.get("t_toast_success_permissions_updated"),
          "success"
        );
      } catch (e) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    }
  };

  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit(handlePermissionChange)}>
      <Paper>
        <Typography variant="h3" mb={2.5}>
          {intl.get("t_general_permissions")}
        </Typography>
        <br />
        <Typography color="text.secondary" mb={2}>
          {intl.get("t_employer_manage_team_permission_subtitle")}
        </Typography>
        <Checkbox
          size="medium"
          name="permissions"
          control={control}
          setValue={setValue}
          options={USER_PERMISSIONS_OPTIONS}
          disabled={!canEditIAM}
        />
      </Paper>

      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Button
          variant="outlined"
          handleClick={() =>
            navigate(
              `/${translate.getCurrentLocale()}/employers/settings/manage-team`
            )
          }>
          {intl.get("t_general_cancel")}
        </Button>
        <Button
          loading={isUpdateUserPermissionsLoading}
          disabled={isUpdateUserPermissionsLoading}
          type="submit">
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default UserPermissions;
