import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Divider, Grid, Stack, SxProps, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
  collection,
  collectionGroup,
  getCountFromServer,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonStatisticsCard from "@skeletons/SkeletonStatisticsCard";

import Button from "@components/Button";
import ApplicantsDataTable from "@components/DataTable/ApplicantsDataTable";
import InvitedCandidatesDataTable from "@components/DataTable/InvitedCandidatesDataTable";
import PostedJobsDataTable from "@components/DataTable/PostedJobsDataTable";
import HowTokhimoJobsWorksCard from "@components/HowTokhimoJobsWorksCards";
import Paper from "@components/Paper";
import StatisticsCard from "@components/StatisticsCard";
import { Tab, TabPanel, Tabs } from "@components/Tab";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_POST_STATUS_CODE
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { getPermission } from "@utils/getPermission";
import { resolveMultiLingual } from "@utils/multiLingual";
import { canUserPostJob } from "@utils/postJob";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

const PaperSxProps: SxProps = {
  maxWidth: "100%",
  borderRadius: "0.25rem",
  [theme.breakpoints.down("sm")]: {
    padding: "1.25rem"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "1.5rem"
  }
};

const TAB_VALUE_APPLIED = 0;
const TAB_VALUE_INVITED = 1;

const EmployersDashboard = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const companyData = useCompanyDetails();
  const canPostJob = getPermission(companyData, user, "canUpsertJob");

  const toast = useToast();

  const companyId = userProfile.value?.company_id ?? "";

  const jobTitleOptions =
    resolveMultiLingual(companyData.value?.job_titles) ?? {};

  const [totalApplications, setTotalApplications] = useState<number>(0);
  const [totalApplicationsLoading, setTotalApplicationsLoading] =
    useState<boolean>(true);
  const [monthlyResumePurchaseCount, setMonthlyResumePurchaseCount] =
    useState<number>(0);
  const [monthlyResumePurchaseLoading, setMonthlyResumePurchaseLoading] =
    useState<boolean>(true);

  const [activeTabValue, setActiveTabValue] = useState<number>(0);

  const handleTabChange = (newValue: number) => {
    setActiveTabValue(newValue);
  };

  const getApplicationCount = async () => {
    const applicationStatus = [
      JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED,
      JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED
    ];

    try {
      setTotalApplicationsLoading(true);
      const applicationsRef = collectionGroup(
        db,
        FIRESTORE_COLLECTIONS.APPLICATIONS
      );
      const applicationQuery = query(
        applicationsRef,
        where("company_id", "==", companyId),
        where("metadata.status", "not-in", applicationStatus)
      );
      const querySnapshot = await getCountFromServer(applicationQuery);
      setTotalApplications(querySnapshot.data().count);
      setTotalApplicationsLoading(false);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  let postJobButtonTooltipMsg = "";
  const postJobStatusCode = canUserPostJob(userProfile, user);
  if (
    postJobStatusCode &&
    postJobStatusCode === JOB_POST_STATUS_CODE.EMAIL_NOT_VERIFIED
  ) {
    postJobButtonTooltipMsg = intl.get("t_general_verify_email");
  } else if (!canPostJob) {
    postJobButtonTooltipMsg = intl.get("t_general_no_permission");
  }

  const getMonthlyResumePurchaseCount = async () => {
    setMonthlyResumePurchaseLoading(true);
    const firstDayOfMonth = dayjs().startOf("month").toDate();
    const lastDayOfMonth = dayjs().endOf("month").toDate();

    const purchasedResumeSubCollectionRef = collection(
      db,
      `${FIRESTORE_COLLECTIONS.COMPANIES}/${companyId}/${FIRESTORE_COLLECTIONS.PURCHASED_RESUMES}`
    );
    const monthlyResumePurchase = await getDocs(
      query(
        purchasedResumeSubCollectionRef,
        where("purchased_at", ">=", firstDayOfMonth),
        where("purchased_at", "<=", lastDayOfMonth)
      )
    );
    setMonthlyResumePurchaseCount(monthlyResumePurchase.size);
    setMonthlyResumePurchaseLoading(false);
  };

  useEffect(() => {
    if (companyId) {
      getApplicationCount();
      getMonthlyResumePurchaseCount();
    }
  }, [companyId]);

  // Searching
  const methods = useForm({
    defaultValues: {
      searchTerm: { key: "", label: "" }
    }
  });
  const { control, watch } = methods;
  const searchedJob = watch("searchTerm");

  return (
    <>
      <Stack
        color="white"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, md: 0 }}
        justifyContent="space-between"
        alignItems={{ xs: "start", sm: "center" }}
        mb={5.5}>
        <Typography variant="h3">
          {intl.get("t_employer_dashboard_title")}
        </Typography>
        {postJobStatusCode === JOB_POST_STATUS_CODE.CAN_POST && canPostJob ? (
          <Button
            color="secondary"
            handleClick={() =>
              navigate(
                `/${translate.getCurrentLocale()}/employers/jobs/new/${translate.getCurrentLocaleShort()}/company-information`
              )
            }>
            {intl.get("t_employer_dashboard_post_job_button")}
          </Button>
        ) : (
          <Tooltip
            arrow
            placement="bottom"
            enterTouchDelay={0}
            title={postJobButtonTooltipMsg}>
            <span>
              <Button disabled color="secondary">
                {intl.get("t_employer_dashboard_post_job_button")}
              </Button>
            </span>
          </Tooltip>
        )}
      </Stack>

      <Grid container spacing={3} mb={3} alignItems="stretch">
        <Grid item xs={12} sm={6} display="flex">
          {totalApplicationsLoading ? (
            <SkeletonStatisticsCard />
          ) : (
            <StatisticsCard
              data-testid="employer_dashboard_total_new_applications_stats_card"
              label={intl.get(
                "t_employer_dashboard_total_new_applications_stats_card_label"
              )}
              count={totalApplications}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          {userProfile.loading || monthlyResumePurchaseLoading ? (
            <SkeletonStatisticsCard />
          ) : (
            <StatisticsCard
              data-testid="employer_dashboard_resume_purchased_stats_card"
              label={intl.get(
                "t_employer_dashboard_resume_purchased_stats_card_label"
              )}
              count={monthlyResumePurchaseCount}
            />
          )}
        </Grid>
      </Grid>

      {/* FIXME: Update loading logic */}
      <Stack spacing={3} mb={22.5}>
        <Typography variant="h3">
          {intl.get("t_employer_dashboard_job_applications_table_title")}
        </Typography>
        <Paper sx={PaperSxProps}>
          <Stack direction="row" alignItems="center" flexWrap="wrap-reverse">
            <Box flexGrow={1}>
              <Tabs value={activeTabValue} aria-label="applications-table">
                <Tab
                  data-testid="employer_dashboard_job_applicants_tab_button"
                  value={TAB_VALUE_APPLIED}
                  isActive={activeTabValue === TAB_VALUE_APPLIED}
                  changeHandler={handleTabChange}
                  key={TAB_VALUE_APPLIED}>
                  {intl.get(
                    "t_employer_dashboard_job_applications_tab_applicants"
                  )}
                </Tab>
                <Tab
                  data-testid="employer_dashboard_job_invited_tab_button"
                  value={TAB_VALUE_INVITED}
                  isActive={activeTabValue === TAB_VALUE_INVITED}
                  changeHandler={handleTabChange}
                  key={TAB_VALUE_INVITED}>
                  {intl.get(
                    "t_employer_dashboard_job_applications_tab_invited"
                  )}
                </Tab>
              </Tabs>
            </Box>
            <Box
              noValidate
              component="form"
              width={{ xs: "100%", sm: 280, md: 300 }}
              flexGrow={{ xs: 1, sm: 0 }}>
              <TypeableSelect
                data-testid="employer_dashboard_search_job_title_select"
                disablePortal={false}
                name="searchTerm"
                startAdornment={<SearchIcon />}
                control={control}
                placeholder={intl.get(
                  "t_employer_dashboard_job_applications_table_search_placeholder"
                )}
                options={Object.values(jobTitleOptions)}
              />
            </Box>
          </Stack>

          <Divider />

          <TabPanel
            value={activeTabValue}
            index={TAB_VALUE_APPLIED}
            key={TAB_VALUE_APPLIED}>
            <Box mt={4.5}>
              <ApplicantsDataTable
                filterJobId={
                  searchedJob && searchedJob.key ? searchedJob.key : ""
                }
              />
            </Box>
          </TabPanel>
          <TabPanel
            value={activeTabValue}
            index={TAB_VALUE_INVITED}
            key={TAB_VALUE_INVITED}>
            <Box mt={4.5}>
              <InvitedCandidatesDataTable
                filterJobId={
                  searchedJob && searchedJob.key ? searchedJob.key : ""
                }
              />
            </Box>
          </TabPanel>
        </Paper>

        <Typography variant="h3">
          {intl.get("t_employer_dashboard_job_posted_table_title")}
        </Typography>
        <Paper sx={PaperSxProps}>
          <PostedJobsDataTable />
        </Paper>

        <HowTokhimoJobsWorksCard />
      </Stack>
    </>
  );
};

export default EmployersDashboard;

// Note: The Candidate information drawer is on Query Parameter. No separate path needed for that.
