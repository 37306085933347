import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Button as MuiButton,
  Stack,
  styled,
  TextField,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonPlanCard from "@skeletons/SkeletonPlanCard";

import ActionButton from "@components/ActionButton";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import Paper from "@components/Paper";
import PlanCard from "@components/PlanCard";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useToast from "@hooks/useToast";

import {
  CREDIT_PURCHASE_QTY_LIMIT,
  DIALOG_ACTION,
  LOCALE,
  STRIPE_MODE,
  STRIPE_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_TERM
} from "@utils/config";
import { auth, functions } from "@utils/firebase";
import { getPermission } from "@utils/getPermission";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

import Cancel from "@assets/images/Cancel.png";
import Success from "@assets/images/Success.png";

interface PurchaseDetails {
  priceId?: string;
  plan?: typeof STRIPE_SUBSCRIPTION_PLAN[keyof typeof STRIPE_SUBSCRIPTION_PLAN];
}

type ActionStatusType =
  | "successCreditPurchase"
  | "cancelCreditPurchase"
  | "successSubscriptionPurchase"
  | "cancelSubscriptionPurchase"
  | "cancelSubscription"
  | null;

const StyledButton = styled(Button)(() => ({
  justifyContent: "flex-start"
}));

const stripePriceIds = {
  credit: process.env.REACT_APP_STRIPE_PRICE_ID_CREDIT_JPY ?? "",
  starterPlanMonthlyJpy:
    process.env.REACT_APP_STRIPE_PRICE_ID_STARTER_MONTHLY_JPY ?? "",
  starterPlanYearlyJpy:
    process.env.REACT_APP_STRIPE_PRICE_ID_STARTER_YEARLY_JPY ?? "",
  proPlanMonthlyJpy:
    process.env.REACT_APP_STRIPE_PRICE_ID_PRO_MONTHLY_JPY ?? "",
  proPlanYearlyJpy: process.env.REACT_APP_STRIPE_PRICE_ID_PRO_YEARLY_JPY ?? ""
};

//FIXME: Update EN and JA translation
const Plans = () => {
  const actionModalContents = {
    successCreditPurchase: {
      img: Success,
      title: intl.get("t_employer_plan_purchase_credit_success_modal_title"),
      message: intl.get("t_employer_plan_purchase_credit_success_modal_message")
    },
    cancelCreditPurchase: {
      img: Cancel,
      title: intl.get("t_employer_plan_purchase_credit_cancel_modal_title"),
      message: intl.get("t_employer_plan_purchase_credit_cancel_modal_message")
    },
    successSubscriptionPurchase: {
      img: Success,
      title: intl.get(
        "t_employer_plan_purchase_subscription_success_modal_title"
      ),
      message: intl.get(
        "t_employer_plan_purchase_subscription_success_modal_message"
      )
    },
    cancelSubscriptionPurchase: {
      img: Cancel,
      title: intl.get(
        "t_employer_plan_purchase_subscription_cancel_modal_title"
      ),
      message: intl.get(
        "t_employer_plan_purchase_subscription_cancel_modal_message"
      )
    },
    cancelSubscription: {
      img: Cancel,
      title: intl.get("t_employer_plan_cancel_subscription_modal_title"),
      message: intl.get("t_employer_plan_cancel_subscription_modal_message")
    }
  };

  const companyDetails = useCompanyDetails();
  const [user] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const canPurchaseCreditPermission = getPermission(
    companyDetails,
    user,
    "canPurchaseCredit"
  );
  const canManagePaymentMethodPermission = getPermission(
    companyDetails,
    user,
    "canManagePaymentMethod"
  );

  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false);
  const [isPurchaseCreditLoading, setIsPurchaseCreditLoading] =
    useState<boolean>(false);
  const [isPurchaseSubscriptionLoading, setIsPurchaseSubscriptionLoading] =
    useState<{
      free: boolean;
      starter: boolean;
      pro: boolean;
    }>({
      free: false,
      starter: false,
      pro: false
    });
  const [isPortalLinkLoading, setIsPortalLinkLoading] =
    useState<boolean>(false);
  const [term, setTerm] = useState<
    typeof SUBSCRIPTION_PLAN_TERM[keyof typeof SUBSCRIPTION_PLAN_TERM]
  >(SUBSCRIPTION_PLAN_TERM.MONTHLY);
  const [prices, setPrices] = useState<Record<string, number>>({});
  const [isFetchPriceSuccess, setIsFetchPriceSuccess] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<number>(1);
  const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
  const [actionStatusType, setActionStatusType] =
    useState<ActionStatusType>(null);

  const creditsAvailable = companyDetails?.value?.total_credits_available ?? 0;
  const stripeCustomerId =
    companyDetails?.value?.billing_information?.stripe_customer_id;
  const subscriptionInfo = companyDetails?.value?.subscription_information;
  const activePlan =
    subscriptionInfo?.plan_type ?? STRIPE_SUBSCRIPTION_PLAN.FREE;

  const currentLocale = translate.getCurrentLocale();

  const actionDialogContent = actionStatusType
    ? actionModalContents[actionStatusType]
    : null;

  useEffect(() => {
    // Fetch Prices from Stripe
    (async () => {
      setIsPriceLoading(true);

      try {
        const getPrices = httpsCallable(functions, "getStripePriceDetails");
        const result = await getPrices();

        if (result.data) {
          setPrices(result.data as Record<string, number>);
          setIsFetchPriceSuccess(true);
        } else {
          setIsFetchPriceSuccess(false);
        }
      } catch (error) {
        setIsFetchPriceSuccess(false);
      } finally {
        setIsPriceLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // Handle success and cancel payment scenarios
    const action = searchParams.get("action") ?? "";
    const status = searchParams.get("status") ?? "";
    let type: ActionStatusType = null;

    if (action === "purchase_credit") {
      if (status === "success") {
        type = "successCreditPurchase";
      } else if (status === "cancel") {
        type = "cancelCreditPurchase";
      }
    } else if (action === "purchase_subscription") {
      if (status === "success") {
        type = "successSubscriptionPurchase";
      } else if (status === "cancel") {
        type = "cancelSubscriptionPurchase";
      }
    } else if (action === "cancel_subscription") {
      type = "cancelSubscription";
    }

    if (type) {
      setActionStatusType(type);
      setShowActionDialog(true);
    } else {
      setShowActionDialog(false);
    }
  }, [searchParams]);

  const deleteSearchParams = () => {
    searchParams.delete("action");
    searchParams.delete("status");
    setSearchParams(searchParams.toString(), { replace: true });
  };

  // Handle Quantity selection for Credit purchase
  const handleIncrement = () => {
    setQuantity((prev) =>
      prev < CREDIT_PURCHASE_QTY_LIMIT.MAX
        ? prev + 1
        : CREDIT_PURCHASE_QTY_LIMIT.MAX
    );
  };

  const handleDecrement = () => {
    setQuantity((prev) =>
      prev > CREDIT_PURCHASE_QTY_LIMIT.MIN
        ? prev - 1
        : CREDIT_PURCHASE_QTY_LIMIT.MIN
    );
  };

  const handleQuantityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim();

    if (value === "") {
      setQuantity(0);
    } else {
      const numericValue = parseInt(value, 10);

      if (
        !isNaN(numericValue) &&
        numericValue >= CREDIT_PURCHASE_QTY_LIMIT.MIN &&
        numericValue <= CREDIT_PURCHASE_QTY_LIMIT.MAX
      ) {
        setQuantity(numericValue);
      }
    }
  };

  const handleQuantitySelectModalClose = async (
    reason: keyof typeof DIALOG_ACTION
  ) => {
    if (reason === DIALOG_ACTION.AGREE) {
      handlePurchaseCredit();
    }
  };

  const handlePurchaseCredit = async () => {
    setIsPurchaseCreditLoading(true);
    let redirectUrl = "";

    try {
      const purchaseCredit = httpsCallable(
        functions,
        "generateStripeCheckoutSession"
      );

      const result = await purchaseCredit({
        priceId: stripePriceIds.credit,
        quantity: quantity,
        mode: STRIPE_MODE.PAYMENT,
        locale: translate.getLocaleShort(currentLocale)
      });

      redirectUrl = result?.data as string;

      if (!redirectUrl) {
        throw new Error(
          "Invalid redirectUrl: URL not received in correct format."
        );
      }

      // Redirect to Stripe Checkout URL
      window.location.href = redirectUrl;
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    } finally {
      setIsPurchaseCreditLoading(false);
    }
  };

  const handleSubscribePlan = async ({ priceId, plan }: PurchaseDetails) => {
    let redirectUrl = "";

    setIsPurchaseSubscriptionLoading((prevStates) => ({
      ...prevStates,
      [plan?.toLowerCase() as string]: true
    }));

    try {
      const purchaseSubscription = httpsCallable(
        functions,
        "generateStripeCheckoutSession"
      );

      const result = await purchaseSubscription({
        priceId: priceId,
        mode: STRIPE_MODE.SUBSCRIPTION,
        companyName: resolveMultiLingual(companyDetails?.value?.name),
        locale: translate.getLocaleShort(intl.getInitOptions().currentLocale)
      });

      redirectUrl = result?.data as string;

      if (!redirectUrl) {
        throw new Error(
          "Invalid redirectUrl: URL not received in correct format."
        );
      }

      window.location.href = redirectUrl;
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    } finally {
      setIsPurchaseSubscriptionLoading((prevStates) => ({
        ...prevStates,
        [plan?.toLowerCase() as string]: false
      }));
    }
  };

  const cancelSubscriptionPlan = async () => {
    setIsPurchaseSubscriptionLoading((prevStates) => ({
      ...prevStates,
      free: true
    }));

    try {
      const cancelSubscription = httpsCallable(
        functions,
        "cancelPlanSubscription"
      );
      await cancelSubscription();

      searchParams.set("action", "cancelSubscription");
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    } finally {
      setIsPurchaseSubscriptionLoading((prevStates) => ({
        ...prevStates,
        free: false
      }));
    }
  };

  const createCustomerPortalLink = async () => {
    setIsPortalLinkLoading(true);
    let redirectUrl = "";

    try {
      const createCustomerPortal = httpsCallable(
        functions,
        "generateStripeCustomerPortal"
      );

      const result = await createCustomerPortal();
      redirectUrl = result?.data as string;

      if (!redirectUrl) {
        throw new Error(
          "Invalid redirectUrl: URL not received in correct format."
        );
      }

      // Open Stripe Customer Portal URL in a new tab
      window.open(redirectUrl, "_blank");
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    } finally {
      setIsPortalLinkLoading(false);
    }
  };

  const PlanCards = () => {
    return (
      <Grid container gap={2} width="100%">
        <Grid item xs={12} md={3.75}>
          <PlanCard
            plan={STRIPE_SUBSCRIPTION_PLAN.FREE}
            term={term}
            isCurrentPlan={activePlan === STRIPE_SUBSCRIPTION_PLAN.FREE}
            isDisabled={
              isPurchaseSubscriptionLoading.starter ||
              isPurchaseSubscriptionLoading.pro
            }
            isPriceLoading={!isFetchPriceSuccess}
            isPurchaseLoading={isPurchaseSubscriptionLoading.free}
            handleSubscribe={cancelSubscriptionPlan}
          />
        </Grid>
        <Grid item xs={12} md={3.75}>
          <PlanCard
            plan={STRIPE_SUBSCRIPTION_PLAN.STARTER}
            term={term}
            isCurrentPlan={activePlan === STRIPE_SUBSCRIPTION_PLAN.STARTER}
            isDisabled={
              isPurchaseSubscriptionLoading.free ||
              isPurchaseSubscriptionLoading.pro
            }
            isPriceLoading={!isFetchPriceSuccess}
            pricePerMonth={prices[stripePriceIds.starterPlanMonthlyJpy]}
            pricePerYear={prices[stripePriceIds.starterPlanYearlyJpy]}
            isPurchaseLoading={isPurchaseSubscriptionLoading.starter}
            handleSubscribe={() =>
              handleSubscribePlan({
                priceId:
                  term === SUBSCRIPTION_PLAN_TERM.MONTHLY
                    ? stripePriceIds.starterPlanMonthlyJpy
                    : stripePriceIds.starterPlanYearlyJpy,
                plan: STRIPE_SUBSCRIPTION_PLAN.STARTER
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={3.75}>
          <PlanCard
            plan={STRIPE_SUBSCRIPTION_PLAN.PRO}
            term={term}
            isCurrentPlan={activePlan === STRIPE_SUBSCRIPTION_PLAN.PRO}
            isDisabled={
              isPurchaseSubscriptionLoading.free ||
              isPurchaseSubscriptionLoading.starter
            }
            isPriceLoading={!isFetchPriceSuccess}
            pricePerMonth={prices[stripePriceIds.proPlanMonthlyJpy]}
            pricePerYear={prices[stripePriceIds.proPlanYearlyJpy]}
            isPurchaseLoading={isPurchaseSubscriptionLoading.pro}
            handleSubscribe={() =>
              handleSubscribePlan({
                priceId:
                  term === SUBSCRIPTION_PLAN_TERM.MONTHLY
                    ? stripePriceIds.proPlanMonthlyJpy
                    : stripePriceIds.proPlanYearlyJpy,
                plan: STRIPE_SUBSCRIPTION_PLAN.PRO
              })
            }
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Paper>
        {/* Page Title & Sub-Title */}
        <Typography variant="h3">
          {intl.get("t_employer_plan_page_heading")}
        </Typography>
        <br />
        <Typography mt={2.5} color="text.secondary">
          {intl.get("t_employer_plan_page_sub_heading")}
        </Typography>
        <Stack gap={5} width="100%" mt={2}>
          {/* Plan Frequency Selector */}
          <Stack direction="row" justifyContent="center">
            <ButtonGroup disabled={!isFetchPriceSuccess}>
              <MuiButton
                variant={
                  term === SUBSCRIPTION_PLAN_TERM.MONTHLY
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setTerm(SUBSCRIPTION_PLAN_TERM.MONTHLY)}>
                {intl.get("t_employer_plan_frequency_monthly")}
              </MuiButton>
              <MuiButton
                variant={
                  term === SUBSCRIPTION_PLAN_TERM.YEARLY
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setTerm(SUBSCRIPTION_PLAN_TERM.YEARLY)}>
                {intl.get("t_employer_plan_frequency_yearly")}
              </MuiButton>
            </ButtonGroup>
          </Stack>
          {/* Plan Cards */}
          {isPriceLoading ? (
            <Grid container spacing={2} width="100%">
              {[...Array(3)].map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <SkeletonPlanCard />
                </Grid>
              ))}
            </Grid>
          ) : (
            <PlanCards />
          )}

          {/* Customer Portal Link */}
          <Box alignSelf="end">
            {canManagePaymentMethodPermission ? (
              <Button
                variant="light"
                color="secondary"
                loading={isPortalLinkLoading}
                disabled={!stripeCustomerId}
                handleClick={createCustomerPortalLink}>
                {intl.get("t_employer_plan_page_customer_portal_btn")}
              </Button>
            ) : (
              <Tooltip
                arrow
                enterTouchDelay={0}
                title={intl.get("t_general_no_permission")}>
                <span>
                  <Button variant="light" color="secondary" disabled>
                    {intl.get("t_employer_plan_page_customer_portal_btn")}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>

          {/* Plan Status */}
          <Stack>
            <Typography variant="h4" mb={2}>
              {intl.get("t_employer_plan_page_plan_status_heading")}
            </Typography>
            <Stack gap={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="body1">
                  {intl.get("t_employer_plan_page_plan_status_your_plan")}
                </Typography>
                <Typography variant="body1">
                  {activePlan === STRIPE_SUBSCRIPTION_PLAN.PRO
                    ? intl.get("t_employer_plan_status_pro")
                    : activePlan === STRIPE_SUBSCRIPTION_PLAN.STARTER
                    ? intl.get("t_employer_plan_status_starter")
                    : intl.get("t_employer_plan_status_free")}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="body1">
                  {intl.get("t_employer_plan_page_plan_status_active_till")}
                </Typography>
                <Typography variant="body1">
                  {activePlan !== STRIPE_SUBSCRIPTION_PLAN.FREE
                    ? dayjs(subscriptionInfo?.plan_renewal_at.toDate()).format(
                        currentLocale === LOCALE.EN
                          ? "DD/MM/YYYY"
                          : "MM/DD/YYYY"
                      )
                    : intl.get(
                        "t_employer_plan_page_plan_status_active_till_free_plan"
                      )}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="body1">
                  {intl.get(
                    "t_employer_plan_page_plan_status_received_this_month"
                  )}
                </Typography>
                <Typography variant="body1">{creditsAvailable}</Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* Add Credit */}
          <Stack gap={2}>
            <Typography variant="h4">
              {intl.get("t_employer_plan_page_add_credit_heading")}
            </Typography>
            <Typography variant="body1">
              {intl.get("t_employer_plan_page_total_credit")}
            </Typography>
            <Typography variant="h4" color="primary">
              {creditsAvailable}
            </Typography>
            <Divider />
            {!canPurchaseCreditPermission ? (
              <Tooltip
                arrow
                enterTouchDelay={0}
                title={intl.get("t_general_no_permission")}>
                <Box component="span" width="max-content">
                  <StyledButton
                    disabled
                    variant="text"
                    size="small"
                    fullWidth={false}
                    startAdornment={<AddCircleIcon />}>
                    {intl.get("t_employer_plan_add_tokhimo_credit")}
                  </StyledButton>
                </Box>
              </Tooltip>
            ) : (
              <Dialog
                initiator={
                  <StyledButton
                    variant="text"
                    size="small"
                    fullWidth={false}
                    startAdornment={<AddCircleIcon />}>
                    {intl.get("t_employer_plan_add_tokhimo_credit")}
                  </StyledButton>
                }
                maxWidth="xs"
                title={intl.get(
                  "t_employer_plan_page_credit_quantity_modal_title"
                )}
                onClose={handleQuantitySelectModalClose}>
                {(handleAgree) => (
                  <>
                    <DialogContent>
                      <Stack
                        direction="row"
                        gap={2}
                        justifyContent="center"
                        alignItems="center">
                        <ActionButton
                          icon="remove"
                          handleAction={handleDecrement}
                        />
                        <TextField
                          value={quantity === 0 ? "" : quantity.toString()}
                          type="number"
                          onChange={handleQuantityInputChange}
                          inputProps={{
                            sx: { textAlign: "center" },
                            maxLength: 3
                          }}
                          variant="outlined"
                          size="small"
                          sx={{ width: "80px" }}
                        />
                        <ActionButton
                          icon="add"
                          handleAction={handleIncrement}
                        />
                      </Stack>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        handleClick={handleAgree}
                        fullWidth
                        disabled={quantity < 1}>
                        {intl.get("t_general_confirm")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            )}
          </Stack>
        </Stack>
      </Paper>

      {/* Modals */}
      {isPurchaseCreditLoading ? (
        <Dialog
          initiator=""
          isDefaultOpen
          maxWidth="xs"
          showCloseBtn={false}
          isPersistent>
          {() => (
            <DialogContent>
              <Stack alignItems="center" justifyContent="center" height="50vh">
                <CircularProgress color="primary" size={60} />
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}

      {showActionDialog && actionDialogContent ? (
        <Dialog
          initiator=""
          isDefaultOpen={true}
          maxWidth="xs"
          onClose={deleteSearchParams}>
          {() => (
            <DialogContent>
              <Stack justifyContent="center" alignItems="center">
                <Box mb={3}>
                  <img
                    src={actionDialogContent.img}
                    width={80}
                    height={80}
                    alt={actionDialogContent.title}
                  />
                </Box>
                <Typography variant="h5" mb={1}>
                  {actionDialogContent.title}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="text.secondary">
                  {actionDialogContent.message}
                </Typography>
              </Stack>
            </DialogContent>
          )}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};

export default Plans;
