import { User as FirebaseUser } from "firebase/auth";

import CompanyManagerIAM from "@interfaces/database/CompanyManagerIAM";

import { CompanyDetailsContextStructure } from "@utils/components/CompanyDetailsContext";

/**
 * Retrieves the specified permission status for a user within a company.
 *
 * @param {CompanyDetailsContextStructure} companyDetails - The details of the company, including managers and owner information.
 * @param {FirebaseUser | null | undefined} user - The current authenticated Firebase user.
 * @param {keyof CompanyManagerIAM} permissionKey - The specific permission key to check within the user's permissions.
 *
 * @returns {boolean} Returns true if the user is the owner or has the specified permission; otherwise, returns false.
 */

export const getPermission = (
  companyDetails: CompanyDetailsContextStructure,
  user: FirebaseUser | null | undefined,
  permissionKey: keyof CompanyManagerIAM
) => {
  if (!user) {
    return false;
  }

  const permissions = companyDetails?.value?.managers?.[user.uid]?.permissions;
  const ownerUserId = companyDetails?.value?.owner?.user_id;

  if (user.uid === ownerUserId) {
    return true;
  }

  if (permissionKey) {
    return permissions?.[permissionKey];
  }
  return false;
};
